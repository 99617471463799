import { render, staticRenderFns } from "./Full.vue?vue&type=template&id=1ef5746b&scoped=true&"
import script from "./Full.vue?vue&type=script&lang=ts&"
export * from "./Full.vue?vue&type=script&lang=ts&"
import style0 from "./Full.vue?vue&type=style&index=0&id=1ef5746b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef5746b",
  null
  
)

export default component.exports
import {QCard,QItemLabel,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QItemLabel,QIcon})
