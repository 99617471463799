var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"column justify-start items-start meeting-card-full td-rounded-8 redesign",class:{
    'meeting-card-full__required cursor-pointer': !_vm.isBusy,
    'meeting-card-full__busy cursor-inherit': _vm.isBusy,
    'meeting-card-full__selected': _vm.isSelected,
  },attrs:{"flat":"","tag":"a","href":("/" + _vm.currentTeamId + "/chats/" + (_vm.meeting.jid) + "/")},on:{"click":function (event) { return _vm.openMeeting(event); }}},[_c('div',{staticClass:"row items-center justify-between full-width meeting-card-full__head no-wrap"},[_c('q-item-label',{staticClass:"meeting-card-full__title",attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(_vm.isBusy ? _vm.$t('meetings.card.busy') : _vm.meeting.displayName)+" ")]),(_vm.canShowActions)?_c('MeetingCardActions',{class:{
        'meeting-card-actions': !_vm.isShowActions
      },attrs:{"meeting":_vm.meeting}}):_vm._e(),(!_vm.isShowActions && _vm.meeting.freq)?_c('q-icon',{class:{
        'meeting-card-freq': _vm.canShowActions
      },attrs:{"color":"base-brand","size":"14px"}},[_c('IconRepeat2')],1):_vm._e()],1),_c('MeetingCardMainInfo',{attrs:{"meeting":_vm.meeting,"is-show-icon":"","is-show-interval":"","is-show-day":_vm.isShowDay}}),(_vm.meeting.freq && _vm.isShowActions)?_c('div',{staticClass:"q-mt-sm row items-center no-wrap full-width"},[_c('q-icon',{staticClass:"meeting-card-full__repeat-icon",attrs:{"size":"12px","color":"base-brand"}},[_c('IconRepeat2')],1),_c('q-item-label',{staticClass:"q-ml-xs",attrs:{"lines":"1"}},[_vm._v(" "+_vm._s(_vm.repeatabilityDescription)+" ")])],1):_vm._e(),(_vm.isShowMembers)?_c('div',{staticClass:"q-mt-sm flex items-start justify-between full-width"},[(_vm.meeting.meetingMembers)?_c('MeetingCardMembers',{attrs:{"members":_vm.meeting.meetingMembers}}):_vm._e(),(_vm.meeting.numUnread || _vm.meeting.numUnreadNotices)?_c('MeetingCardMessageCounter',{attrs:{"num-unread":_vm.meeting.numUnread,"num-unread-notices":_vm.meeting.numUnreadNotices}}):_vm._e()],1):_vm._e(),(_vm.isCalendarCard)?_c('MeetingTooltip',{attrs:{"meeting":_vm.meeting}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }