























































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { Meeting } from '@tada-team/tdproto-ts'
import { teamsStore } from '@/store'

@Component({
  components: {
    MeetingCardMainInfo: () => import('./components/MainInfo.vue'),
    MeetingCardMembers: () => import('./components/Members.vue'),
    MeetingCardMessageCounter: () => import('../components/MessageCounter.vue'),
    MeetingCardActions: () => import('./components/Actions.vue'),
    IconRepeat2: () => import('@tada/icons/dist/IconRepeat2.vue'),
    MeetingTooltip: () => import('./components/Tooltip.vue'),
  },
})
export default class MeetingCard extends Vue {
  @Prop({
    required: true,
    type: Object,
  }) readonly meeting!: Meeting

  @Prop({
    required: false,
    type: Boolean,
  }) readonly isShowMembers!: boolean

  @Prop({

    type: Boolean,
  }) readonly isSelected!: boolean

  @Prop({
    type: Boolean,
  }) readonly isShowActions!: boolean

  @Prop({
    type: Boolean,
  }) readonly isShowDay!: boolean

  @Prop({
    type: Boolean,
  }) readonly isBusy!: boolean

  @Prop({
    type: Boolean,
  }) isCalendarCard?: boolean

  get canShowActions () {
    return this.meeting.canDelete || this.meeting.canEdit
  }

  get currentTeamId () {
    return teamsStore.getters.currentTeam.uid
  }

  private openMeeting (event: MouseEvent): void {
    if ((event.ctrlKey || event.shiftKey || event.metaKey) && !this.isBusy) {
      return
    }
    event.preventDefault()
    if (this.isBusy) return
    this.$router.push({
      name: 'Chat',
      params: {
        jid: this.meeting.jid,
        teamId: teamsStore.getters.currentTeam.uid,
      },
    })
  }

  get repeatabilityDescription () {
    return this.$t('meetings.card.repeatabilityDescription', {
      repeatability: this.meeting.freq?.repeatabilityDescription ?? '',
    }).toString()
  }
}
